//  零售订单发货成功
export default {
  name: "order-delivery-success",

  methods: {
    onBackHome() {
      const homePage = this.$store.state.permission.permission.app["home-page"];
      this.$router.replace({
        name: homePage || "product-list",
      });
    },
  },
};
